import { ReactNode } from "react";
import {
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  SxProps,
} from "@mui/material";
import { theme } from "../../theme";
import { TypesOfStatus } from "../PaymentMethodCard/PaymentMethodCard";
import getREMFromPX from "../../utils/getREMFromPX";

type CardWrapperProps = {
  title?: string;
  status?: TypesOfStatus;
  isPaymentMethodCard?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  sx?: SxProps;
  cardTitleSxProps?: SxProps;
  hasOverflow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const CardWrapper = ({
  title,
  status,
  actions,
  children,
  footer,
  isPaymentMethodCard = false,
  hasOverflow = false,
  ...rest
}: CardWrapperProps) => {
  const colors = theme["new"].colors;

  const getBackgroundColor = () => {
    switch (status) {
      case "Overdue":
        return !!isPaymentMethodCard ? colors.pink : colors.white;
      case "Pending":
        return !!isPaymentMethodCard ? colors.yellow : colors.white;
      default:
        return colors.white;
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        bgcolor: getBackgroundColor(),
        borderRadius: "8px",
        boxShadow: "0 4px 25px rgba(201, 218, 216, 0.6)",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        ...rest.sx,
      }}
    >
      <CardContent
        sx={{
          padding: {
            sm: "20px 25px 20px 25px",
            xs: "10px 15px 10px 15px",
          },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowX: hasOverflow ? "scroll" : "",
          ":last-child": {
            paddingBottom: "16px",
          },
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          sx={{
            ...rest.cardTitleSxProps,
            flexDirection: {
              md: "row",
              xs: "column",
            },
            gap: 1,
            alignItems: {
              lg: "center",
              xs: "flex-start",
            },
          }}
        >
          <Typography
            fontFamily="Montserrat"
            fontSize={getREMFromPX(20)}
            fontWeight="600"
            fontStyle="normal"
            letterSpacing={0}
            textAlign="left"
            variant="h3"
            color={colors.darkGreenFontColor}
          >
            {title}
          </Typography>
          <Stack flexDirection="row" alignItems="flex-end">
            {actions}
          </Stack>
        </Stack>
        {children}
      </CardContent>
      {footer && (
        <Box
          sx={{
            backgroundColor:
              status === "Paid"
                ? theme["new"].colors.lightGreen
                : theme["new"].colors.pink,
            paddingBottom: "13px",
            marginTop: "12px",
          }}
        >
          {footer}
        </Box>
      )}
    </Card>
  );
};

export default CardWrapper;
