import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)<any>(({ selected }) => ({
  padding: "0.5rem 0.75rem",
  fontSize: "0.75rem",
  fontWeight: 500,
  border: "1px solid",
  borderColor: selected ? "#416661" : "#DBDBDB",
  color: selected ? "white" : "#416661",
  backgroundColor: selected ? "#416661" : "transparent",
  borderRadius: "0px",
  minWidth: "40px",
  "&:hover": {
    backgroundColor: selected ? "#416661" : "#f0f0f0",
    color: "#416661",
  },
  "&:disabled": {
    color: selected ? "white" : "#6D6D6D",
  },
  "&:active": {
    backgroundColor: "#DBDBDB",
    color: "#416661",
    borderColor: "#DBDBDB",
  },
  borderRight: 0,
}));

const PageButton: React.FC<
  ButtonProps & {
    selected?: boolean;
    dataCy?: string;
  }
> = ({ children, disabled, selected = false, onClick, dataCy, ...props }) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      selected={selected}
      data-cy={dataCy}
      aria-current={selected}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default PageButton;
