import { useContext } from "react";
import {
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../AddressAndAccountProvider";
import { ExpandMore } from "@mui/icons-material";

const SelectDevice = ({ children, ...props }: MenuItemProps) => {
  const { devices, currentDevice, setCurrentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  return (
    <Select
      sx={props.sx}
      value={currentDevice.id}
      onChange={(event: SelectChangeEvent) => {
        const newDevice = devices.find(
          (device) => device.id === event.target.value
        );
        if (newDevice) {
          setCurrentDevice(newDevice);
        }
      }}
      IconComponent={ExpandMore}
      data-cy="myDeviceSelectInput"
    >
      {devices.map((device) => (
        <MenuItem
          key={device.id}
          value={device.id}
          data-cy={`deviceSelect-${device.id}`}
        >
          {`${device.Physicallocation.house_number} ${device.Physicallocation.pre_directional} ${device.Physicallocation.street} ${device.Physicallocation.post_directional} ${device.Physicallocation.apartment_number}`}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SelectDevice;
