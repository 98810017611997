import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapper from "../../../../components/CardWrapper";
import { BackStyle } from "../../Styles";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { LocationIcon, RotateIcon } from "../../../../assets/icons";

import {
  VbgTitle,
  Location,
  VbgInfo,
  StreetInfo,
  Router,
  Hardware,
  MyService,
  ViewMyService,
  MyServiceRow,
} from "./Styles";
import { ButtonService } from "../../Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import MyDevicePortsRow from "../../components/DeviceGuide/MyDevicePortsRow";
import useViewport from "../../../../hooks/useViewport";
import RouterIcon from "@mui/icons-material/Router";
import {
  ONTDeviceFront,
  ONTDeviceBack,
  VenoenDeviceFront,
  VenoenDeviceBack,
  AaeonDeviceFront,
  AaeonDeviceBack,
} from "../../../../assets/device";

import { useIsPortLive } from "../../../../hooks/useIsPortLive";
import SelectDevice from "../../../../components/SelectDevice";

const DeviceGuide = () => {
  const navigate = useNavigate();

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  const { currentDevice, devices } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const portsWithSubscriptions = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.Subscriptions?.length > 0
  );

  const ports = currentDevice?.Portinterfaces;

  const lanOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 1"
  );

  const lanOnePort = lanOnePorts ? lanOnePorts[0] : null;

  const { data: liveLanOnePort } = useIsPortLive(
    lanOnePort?.device_id ?? "",
    lanOnePort?.id ?? ""
  );

  const lanOnePortWithLive = {
    ...lanOnePort,
    ...liveLanOnePort,
  } as any;

  const lanTwoPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 2"
  );

  const lanTwoPort = lanTwoPorts ? lanTwoPorts[0] : null;

  const { data: liveLanTwoPort } = useIsPortLive(
    lanTwoPort?.device_id ?? "",
    lanTwoPort?.id ?? ""
  );

  const lanTwoPortWithLive = {
    ...lanTwoPort,
    ...liveLanTwoPort,
  } as any;

  const lanThreePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 3"
  );

  const lanThreePort = lanThreePorts ? lanThreePorts[0] : null;

  const { data: liveLanThreePort } = useIsPortLive(
    lanThreePort?.device_id ?? "",
    lanThreePort?.id ?? ""
  );

  const lanThreePortWithLive = {
    ...lanThreePort,
    ...liveLanThreePort,
  } as any;

  const lanFourPorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "LAN 4"
  );

  const lanFourPort = lanFourPorts ? lanFourPorts[0] : null;

  const { data: liveLanFourPort } = useIsPortLive(
    lanFourPort?.device_id ?? "",
    lanFourPort?.id ?? ""
  );

  const lanFourPortWithLive = {
    ...lanFourPort,
    ...liveLanFourPort,
  } as any;

  const sfpOnePorts = ports?.filter(
    (portinterface) =>
      portinterface.display === true &&
      portinterface.type === "LAN" &&
      portinterface.display_name === "SFP 1"
  );

  const sfpOnePort = sfpOnePorts ? sfpOnePorts[0] : null;

  const { data: liveSfpOnePort } = useIsPortLive(
    sfpOnePort?.device_id ?? "",
    sfpOnePort?.id ?? ""
  );

  const sfpOnePortWithLive = {
    ...sfpOnePort,
    ...liveSfpOnePort,
  } as any;

  const deviceHaveSubscription =
    (portsWithSubscriptions?.length ?? 0) > 0 ? true : false;

  const wanPorts = currentDevice?.Portinterfaces?.filter(
    (portinterface) =>
      portinterface.display === true && portinterface.type === "WAN"
  );

  const wanPort = wanPorts ? wanPorts[0] : null;

  const { data: livePort } = useIsPortLive(
    wanPort?.device_id ?? "",
    wanPort?.id ?? ""
  );

  const wanPortWithLive = {
    ...wanPort,
    ...livePort,
  } as any;

  const wanPortActive =
    wanPortWithLive?.data?.portinterface.live?.link_state === "up"
      ? true
      : false;

  const lanPortsCollection = [
    lanOnePortWithLive,
    lanTwoPortWithLive,
    lanThreePortWithLive,
    lanFourPortWithLive,
    sfpOnePortWithLive,
  ];

  const allPortsCollection = [
    lanOnePortWithLive,
    lanTwoPortWithLive,
    lanThreePortWithLive,
    lanFourPortWithLive,
    sfpOnePortWithLive,
    wanPortWithLive,
  ];

  const statusMessage = wanPortActive
    ? deviceHaveSubscription
      ? "Having trouble with your services? Please contact your service provider."
      : "Your device is connected. Please head to the services page to subscribe to a service."
    : "Your device is not connected. Please troubleshoot your device.";

  const statusHeader = wanPortActive ? "Status:" : "Status Report:";

  const serviceAction = wanPortActive ? (
    <ButtonService
      onClick={() => navigate(routes.services.path)}
      sx={{
        pointerEvents: "auto",
        fontSize: "16px",
        display: "inline-flex",
        color: "#fff",
      }}
    >
      More Services
    </ButtonService>
  ) : (
    <ButtonService
      style={{
        pointerEvents: "auto",
        fontSize: "16px",
        display: "inline-flex",
        backgroundColor: "#e8e8e8",
        cursor: "auto",
      }}
    >
      More Services
    </ButtonService>
  );

  const deviceStatus = wanPortActive ? (
    <ButtonService
      style={{
        margin: "0",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#84e88e",
        cursor: "auto",
        borderRadius: "32px",
      }}
    >
      Device Connected
    </ButtonService>
  ) : (
    <ButtonService
      style={{
        margin: "0",
        fontSize: "0.875rem",
        display: "inline-flex",
        height: "48px",
        backgroundColor: "#e8e8e8",
        cursor: "auto",
        borderRadius: "32px",
      }}
    >
      Device is Disabled
    </ButtonService>
  );

  const subscriptionMessage =
    !deviceHaveSubscription && wanPortActive ? (
      <div>
        <Typography
          color={theme.new.colors.darkGreen}
          fontSize={getREMFromPX(12)}
          fontFamily="Montserrat"
          fontWeight={500}
          textAlign="left"
        >
          You are not currently subscribed to any services. Please click "more
          services" to subscribe.
        </Typography>
      </div>
    ) : (
      ""
    );

  const [openDevicesList, setOpenDevicesList] = useState<boolean>(false);

  const { isMobile } = useViewport();

  const [showFront, setShowFront] = useState(true);

  const getDeviceFront = () => {
    switch (currentDevice.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceFront;
      case "Venoen":
        return VenoenDeviceFront;
      case "Kontron ONT":
        return ONTDeviceFront;
      default:
        return AaeonDeviceFront;
    }
  };

  const getDeviceBack = () => {
    switch (currentDevice.Product?.Manufacturer?.name) {
      case "Aaeon":
        return AaeonDeviceBack;
      case "Venoen":
        return VenoenDeviceBack;
      case "Kontron ONT":
        return ONTDeviceBack;
      default:
        return AaeonDeviceBack;
    }
  };

  const DeviceFront = getDeviceFront();
  const DeviceBack = getDeviceBack();
  const DeviceImage = showFront ? DeviceFront : DeviceBack;

  return (
    <NavContainer title="Help">
      <CardWrapper title="Device Information" actions={backAction}>
        <VbgTitle>Virtual Broadband Gateway</VbgTitle>
        <VbgInfo>
          <Box
            display="flex"
            onClick={() => setOpenDevicesList(!openDevicesList)}
            sx={{
              position: "relative",
              flexDirection: {
                lg: "row",
                xs: "column",
              },
              alignItems: {
                lg: "center",
                xs: "start",
              },
            }}
          >
            {currentDevice?.Physicallocation.house_number &&
            currentDevice?.Physicallocation.street ? (
              <Typography
                fontSize={isMobile ? getREMFromPX(5) : getREMFromPX(16)}
                fontWeight={500}
                color="#000"
                fontFamily="Montserrat"
                marginRight="5.4px"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Location>
                  <LocationIcon />
                </Location>
                {devices?.length > 1 ? (
                  <SelectDevice
                    sx={{
                      minWidth: "30%",
                      height: "32px",
                      borderRadius: "10px",
                      marginRight: "20px",
                      fontSize: {
                        lg: "16px",
                        xs: "12px",
                      },
                    }}
                  />
                ) : (
                  <StreetInfo>
                    {`${currentDevice?.Physicallocation.house_number} ${currentDevice?.Physicallocation.street}`}
                  </StreetInfo>
                )}
              </Typography>
            ) : null}
            {currentDevice?.hardware_version ? (
              <Typography
                fontSize={isMobile ? getREMFromPX(5) : getREMFromPX(16)}
                fontWeight={500}
                color="#000"
                fontFamily="Montserrat"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Router>
                  <RouterIcon />
                </Router>
                <Hardware>{currentDevice?.hardware_version}</Hardware>
              </Typography>
            ) : null}
          </Box>
        </VbgInfo>
        <Grid container overflow={"auto"}>
          <Grid item md={12} lg={6}>
            <MyServiceRow>
              <MyService>My Services</MyService>
              <ViewMyService
                to={routes.myServices.path}
                aria-label="View your services"
              >
                View My Services
              </ViewMyService>
            </MyServiceRow>
            <Grid
              container
              style={{ marginLeft: "16px", width: "97%", marginBottom: "35px" }}
            >
              <Grid item>{subscriptionMessage}</Grid>
            </Grid>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "left" }}>Port</TableCell>
                    {isMobile ? (
                      <TableCell style={{ textAlign: "left" }}>Info</TableCell>
                    ) : (
                      <>
                        <TableCell style={{ textAlign: "left" }}>
                          Provider
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          Plan
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          Type
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          Status
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lanPortsCollection?.map((port) => {
                    return <MyDevicePortsRow port={port} key={port.id} />;
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "2rem",
                marginTop: "24px",
              }}
            >
              {serviceAction}
              <ViewMyService
                to={routes.helpTroubleshoot.path}
                aria-label="View Troubleshooting instructions"
              >
                Troubleshooting
              </ViewMyService>
            </Box>

            <Grid
              item
              sx={{
                fontSize: "0.875rem",
                textAlign: "center",
                marginTop: {
                  lg: "93.5px",
                  xs: "24px",
                },
              }}
            >
              <p>
                Having trouble with your services? Please contact your service
                provider.
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={6}
            sx={{
              zIndex: 2,
              marginTop: {
                lg: "235px",
                sm: 10,
                xs: 0,
              },
              order: {
                lg: 1,
                xs: -1,
              },
            }}
          >
            <Box
              sx={{
                marginLeft: {
                  lg: "60px",
                  xs: 0,
                },
                textAlign: "center",
              }}
            >
              <DeviceImage ports={allPortsCollection} />
              <Stack
                onClick={() => setShowFront(!showFront)}
                sx={{
                  marginTop: getREMFromPX(10),
                  width: "100%",
                  maxWidth: getREMFromPX(550),
                  paddingRight: getREMFromPX(30),
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                  fontSize: getREMFromPX(14),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                    border: "1px solid #d0d5dd",
                    borderRadius: "4px",
                    padding: "2px 10px",
                    gap: "4px",
                  }}
                >
                  Rotate
                  <RotateIcon
                    style={{
                      height: "14px",
                      width: "14px",
                      fill: "inherit",
                    }}
                  />
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                marginLeft: {
                  lg: "60px",
                  xs: 0,
                },
                marginTop: {
                  lg: "135px",
                  xs: 4,
                },
                border: "1px solid #DBDBDB",
                padding: "16px 8px",
                borderRadius: "8px",
              }}
            >
              <Grid container alignItems="center" style={{ marginTop: "-6px" }}>
                <Grid
                  item
                  borderLeft="2px solid white"
                  style={{
                    paddingTop: "3px",
                    paddingBottom: "4px",
                    paddingLeft: "12px",
                  }}
                >
                  <Typography
                    color={theme.new.colors.darkGreenFontColor}
                    fontSize={getREMFromPX(14)}
                    fontFamily="Montserrat"
                    fontWeight={800}
                    marginBottom="5px"
                  >
                    <>{statusHeader}</>
                  </Typography>
                  <Typography
                    color={theme.new.colors.darkGreenFontColor}
                    fontSize={getREMFromPX(12)}
                    fontFamily="Montserrat"
                    fontWeight={500}
                  >
                    <>{statusMessage}</>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ textAlign: "right", marginTop: "48px" }}>
              {deviceStatus}
            </Box>
          </Grid>
        </Grid>
      </CardWrapper>
    </NavContainer>
  );
};
export default DeviceGuide;
